<!-- 结算明细-查看详情 -->
<template>
  <div>
    <back />
    <div class="detail" v-loading="loading">
      <h3 class="title">订单信息</h3>

      <el-row class="row">
        <el-col :span="8">
          <div class="col"><span class="txt">订单编号:</span>{{ detail?.orderCode }}</div>
          <div class="col"><span class="txt">结算金额(元):</span>{{ detail?.amount }}</div>

        </el-col>
        <el-col :span="8">
          <div class="col"><span class="txt">充值时间:</span>{{ detail?.createdTime }}</div>
        </el-col>
        <el-col :span="8">
          <div class="col"><span class="txt">订单金额(元):</span>{{ detail?.amount }}</div>
        </el-col>
      </el-row>



      <h3 class="title">充值信息</h3>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="500">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdUserName" label="用户名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="memberCode" label="会员码"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="beforeBalance"
            label="充值前账户余额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="amount" label="充值金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="giveAmount" label="赠送金额(元)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="afterBalance"
            label="充值后账户余额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="充值时间"></el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      orderCode: '',
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.orderCode = this.$route.query.orderCode;
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.$axios.get(this.$api.getShopMemberRechargeByCode, {
        params: {
          orderCode: this.orderCode
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
          this.tableData = [res.data.result];
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  }

}
</script>

<style lang="scss" scoped>
.detail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
  }

  .red {
    color: red;
  }

  .txt {
    margin-right: 8px;
  }

}
</style>
